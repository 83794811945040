import {
  Avatar,
  Box,
  ContentCard,
  Heading,
  InteractiveElementType,
  Paragraph,
  StepIndicator,
  Text,
} from '@localyze-pluto/components';

import React from 'react';
import { SatisfactionScore } from './SatisfactionScore';
import { CreateCaseSteps } from '../types';
import ActivateIntegration from 'assets/img/create_case/activate-integration-cta.jpg';
import { useActiveCompanyHRISIntegration } from 'modules/integrations/hooks/useActiveCompanyHRISIntegration/useActiveCompanyHRISIntegration';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useCurrentUser } from 'config/CurrentUserContext';
import { TalentSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';

type Props = {
  currentStep: number;
  talent?: TalentSearchResult;
};

const INDEX_OFFSET = 1;

const SCORE_ITEMS = [
  'Directly select additional services',
  'Set an allowance and let talents choose the services that are most valuable to them',
];

export const StepInfoCard = ({ currentStep, talent }: Props): React.JSX.Element => {
  const steps = [
    'Select talent',
    'Case type and service level',
    'Job details',
    'Additional Services',
  ];
  const title = steps[currentStep];
  const routes = useRoutes();

  const { isAdmin, company_id } = useCurrentUser();
  const { data: activeHRISIntegration, isPending } = useActiveCompanyHRISIntegration(company_id);

  const shouldShowCallForAction = !isPending && !activeHRISIntegration;

  return (
    <Box.div>
      <Box.div
        borderColor="colorBorderWeaker"
        borderRadius="borderRadius20"
        borderStyle="borderStyleSolid"
        borderWidth="borderWidth10"
        display="flex"
        flexDirection="column"
        gap={talent ? 'd4' : 'd12'}
        marginBottom="m4"
        padding="p5"
      >
        {talent && (
          <Box.div alignItems="center" display="flex" gap="d3">
            <Avatar
              name={fullName({ first_name: talent.first_name, last_name: talent.last_name })}
              size="large"
              src={talent.avatar_url || undefined}
            />
            <Paragraph fontSize="fontSize30" fontWeight="fontWeightMedium" marginBottom="d0">
              {talent.first_name} {talent.last_name}
            </Paragraph>
          </Box.div>
        )}
        <Box.div py="d4">
          <StepIndicator current={currentStep} steps={steps} />
        </Box.div>
        <Box.div>
          <Box.div marginBottom="m2">
            <Text.h3 color="colorTextHeadingStronger" fontSize="fontSize30">
              {`Step ${currentStep + INDEX_OFFSET}`}
            </Text.h3>
          </Box.div>
          <Heading as="h2" size="heading50">
            {title}
          </Heading>
        </Box.div>
      </Box.div>
      {currentStep === CreateCaseSteps.Step1 && shouldShowCallForAction && !isAdmin && (
        <Box.div display="flex" justifyContent="center">
          <ContentCard
            background="inverse"
            callToAction="Activate integration"
            href={routes.integrations.url}
            imageAlt="HR integration provider logos"
            imagePosition="top"
            imageSrc={ActivateIntegration}
            interactiveElementType={InteractiveElementType.Button}
            maxWidth={'100%'}
            text="Click below to activate your HRIS integration!"
            title="Are you tired of manually entering your talent’s data?"
          />
        </Box.div>
      )}
      {currentStep === CreateCaseSteps.Step4 && (
        <SatisfactionScore items={SCORE_ITEMS} score={4.4} />
      )}
    </Box.div>
  );
};
