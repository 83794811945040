import {
  Box,
  Button,
  Label,
  ModalBody,
  ModalFooter,
  TextArea,
  useToast,
} from '@localyze-pluto/components';
import React, { useState } from 'react';

import { CharLimits } from '../../types/CharLimits';
import { CharacterCounter } from './CharacterCounter';
import { DisclosureStore } from '@ariakit/react';
import { Macro, MacroDropdown } from '../MacroDropdown/MacroDropdown';
import { PostType } from '../../types/PostType';
import { StatusUpdateType } from 'modules/statusUpdates/types/StatusUpdateType';
import { WaitingUntilDatePicker } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/components/Edit/WaitingUntilDatePicker';
import { labels } from './labels';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useUID } from 'react-uid';
import { useStatusUpdateMacros } from '../MacroDropdown/useStatusUpdateMacros';
import { useTaskMacros } from '../MacroDropdown/useTaskMacros';
import { useCreateMessage } from 'modules/talent/api/useCreateMessage/useCreateMessage';
import { useCreateStatusUpdate } from 'modules/statusUpdates/api/useCreateStatusUpdate/useCreateStatusUpdate';
import { AxiosError, isAxiosError } from 'axios';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';
import { useCreateTask } from 'modules/tasks/api/useCreateTask/useCreateTask';

export type EditProps = {
  modalState: DisclosureStore;
  postType: PostType;
  charLimits: CharLimits;
  onClickBack?: () => void;
  caseId: number;
};

export const Edit = ({
  onClickBack,
  caseId,
  postType,
  charLimits,
  modalState,
}: EditProps): React.ReactElement => {
  const toast = useToast();
  const [message, setMessage] = useState('');
  const [waitingUntil, setWaitingUntil] = useState<string>('');
  const macroInputId = useUID();
  const [macroType, setMacroType] = useState<StatusUpdateType | undefined>('other');

  const { mutate: createMessage } = useCreateMessage({
    onSuccess: () => {
      toast(`New ${postType} was created successfully.`, 'success');
      modalState.hide();
    },
    onError: (error) => {
      const toastMessage = error.message || `Error creating new ${postType}.`;

      toast(toastMessage, 'error');
    },
    internalMessage: postType === PostType.InternalNote,
  });

  const statusUpdateName = labels[postType].name.toLowerCase();

  const isMilestone = postType === PostType.Milestone;
  const isBlocker = postType === PostType.Blocker;
  const isTask = postType === PostType.Task;

  const hasMilestoneMacroText = isMilestone && (message.includes('[') || message.includes(']'));
  const isMilestoneOrBlocker = isMilestone || isBlocker;

  const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const message = ev.target.value;
    setMessage(message);
  };

  const handleMacroChange = (macro: Macro) => {
    if ('statusType' in macro) setMacroType(macro.statusType[0]);
    setMessage(macro.message);
  };

  const { data: taskMacros, isSuccess: isTaskMacrosSuccess } = useTaskMacros({
    enabled: isTask,
  });

  const { data: statusUpdateMacros, isSuccess: isStatusUpdateMacrosSuccess } =
    useStatusUpdateMacros({
      type: postType,
      enabled: isMilestoneOrBlocker,
    });

  const { mutate: createStatusUpdate } = useCreateStatusUpdate({
    onSuccess: ({ data: { status_type: statusType } }) => {
      toast(`New ${isMilestone ? 'milestone' : 'blocker'} was created successfully.`, 'success');

      if (isMilestone) {
        trackEvent('talent profile: post a milestone', { macroType: statusType });
      }

      modalState.hide();
    },
    onError: (error: AxiosError) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(
        errorMessage || `Error creating new ${isMilestone ? 'milestone' : 'blocker'}.`,
        'error',
      );
    },
  });

  const { mutate: createTask } = useCreateTask({
    onSuccess: () => {
      toast('New Task was created successfully.', 'success');
      modalState.hide();
    },
    onError: (error) => {
      const errorMessage = isAxiosError(error)
        ? (error as AxiosErrorWithMessage).response?.data.message
        : '';
      toast(errorMessage || 'Error creating new Task.', 'error');
    },
  });

  const handlePostOnClick = () => {
    if ([PostType.Message, PostType.InternalNote].includes(postType)) {
      createMessage({
        caseId,
        body: message,
        internal: postType === PostType.InternalNote,
      });
    } else if (postType == PostType.Task) {
      createTask({
        description: message,
        caseId,
      });
    } else {
      createStatusUpdate({
        subjectId: caseId,
        subjectType: 'Case',
        message,
        statusType: (isBlocker ? 'blocker' : macroType) as StatusUpdateType,
        waitingUntil: waitingUntil ? `${waitingUntil}T12:00:00.000Z` : '',
      });
    }
  };

  return (
    <>
      <ModalBody disableOverflow>
        <Box.div display="flex" gap="d2" marginBottom="m2">
          {isMilestoneOrBlocker && (
            <WaitingUntilDatePicker
              caseId={caseId}
              setWaitingUntil={setWaitingUntil}
              waitingUntil={waitingUntil}
            />
          )}
          {(isTaskMacrosSuccess || isStatusUpdateMacrosSuccess) && (
            <Box.div w="10rem">
              <Label htmlFor={macroInputId}>Macro</Label>
              <Box.div id={macroInputId} w="250px">
                <MacroDropdown
                  items={statusUpdateMacros || taskMacros || []}
                  onChange={handleMacroChange}
                />
              </Box.div>
            </Box.div>
          )}
        </Box.div>
        <TextArea
          data-testid="supermodal-textarea"
          maxLength={charLimits[postType]}
          onChange={onChange}
          placeholder="Write something here..."
          value={message}
        />
        <Box.div
          color="colorIconWeaker"
          display="flex"
          fontSize="fontSize20"
          justifyContent="flex-end"
        >
          <Box.div paddingTop="p1">
            <CharacterCounter limit={charLimits[postType]} value={message} />
          </Box.div>
        </Box.div>
      </ModalBody>
      <ModalFooter>
        {onClickBack && (
          <Button onClick={onClickBack} variant="secondary">
            Back
          </Button>
        )}
        <Button
          disabled={!message.length || hasMilestoneMacroText}
          onClick={handlePostOnClick}
          variant="primary"
        >
          Post {statusUpdateName}
        </Button>
      </ModalFooter>
    </>
  );
};
