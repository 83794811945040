import React, { ReactNode, TdHTMLAttributes } from 'react';

import { Box } from '@localyze-pluto/components';
import { Link } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

type Props = TdHTMLAttributes<HTMLTableCellElement> & {
  readonly children: ReactNode;
  readonly talentId: number;
  readonly isAdmin?: boolean;
};

export const TaskCell = ({ talentId, children }: Props): React.JSX.Element => {
  const { isHrViewer } = useCurrentUser();
  const ConditionalLink = isHrViewer ? Box.div : Link;
  const routes = useRoutes();

  return (
    <Box.div
      aria-label={isHrViewer ? undefined : `navigate to talent profile id ${talentId}`}
      as={ConditionalLink}
      color="colorTextStrongest"
      key={talentId}
      onClick={() => {
        trackEvent('my tasks: selects task');
      }}
      textDecoration="none"
      to={isHrViewer ? undefined : routes.talentProfile.get(talentId)}
    >
      <Box.span>{children}</Box.span>
    </Box.div>
  );
};
