import { Avatar, Badge, Box, Button, Heading } from '@localyze-pluto/components';
import { styled, useTheme } from '@localyze-pluto/theme';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Case } from 'modules/cases/types/Case';
import { CaseType } from 'modules/cases/types/CaseType';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { useUser } from 'modules/users/api/useUser/useUser';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentStateBadgeProps } from '../utils/getCurrentStateBadgeProps';
import { CaseUser } from './CaseUser';
import { BackgroundHeader } from 'components/BackgroundHeader/BackgroundHeader';
import { useCurrentUser } from 'config/CurrentUserContext';
import { SERVICE_LEVELS } from 'modules/cases/components/ServiceLevelOptions/serviceLevels';

type CaseDetailsHeaderProps = {
  talentCase: Case;
  caseType: CaseType;
};

const StyledButtonWrapper = styled(Box.div)`
  grid-column-start: 2;
  grid-row-start: 1;

  a {
    color: ${(props: { color: string }) => props.color};
    padding: 0;

    &:hover {
      color: ${(props: { color: string }) => props.color};
      text-decoration: underline;
    }
  }
`;

export const CaseDetailsHeader = ({
  talentCase,
  caseType,
}: CaseDetailsHeaderProps): React.JSX.Element => {
  const theme = useTheme();
  const { isAdmin } = useCurrentUser();
  const routes = useRoutes();

  const { data: talent, isPending: isPendingTalent } = useTalentQuery(talentCase.talent_id);

  const { data: hrManager, isInitialLoading: isInitialLoadingHrManager } = useUser({
    params: { userId: talentCase.hr_manager_id || 0 },
    enabled: !!talentCase.hr_manager_id,
  });

  const cmQueryEnabled = isAdmin && !!talentCase.case_manager_id;
  const { data: caseManager, isPending: isPendingCaseManager } = useUser({
    params: { userId: talentCase.case_manager_id || 0 },
    enabled: cmQueryEnabled,
  });

  if (isPendingTalent || isInitialLoadingHrManager || (cmQueryEnabled && isPendingCaseManager)) {
    return <ContainedLoadingState />;
  }

  const talentFullName = talent ? fullName(talent) : '';
  const hrFullName = hrManager ? fullName(hrManager) : undefined;
  const caseManagerFullName = caseManager && fullName(caseManager);

  const serviceLevelPremium = SERVICE_LEVELS.find(
    (serviceLevel) => serviceLevel.label === 'Premium',
  );

  const isPremiumCase = talentCase.service_level_id === serviceLevelPremium?.id;

  return (
    <BackgroundHeader backgroundImage="/images/header_vector.svg">
      <Box.div alignItems="center" display="flex" justifyContent="space-between" marginBottom="d4">
        <Box.div alignItems="center" display="flex" gap="d2">
          <Avatar name={talentFullName} size="large" src={talent?.avatar_url ?? ''} />
          <Box.span color="colorTextInverse" fontSize="fontSize30" fontWeight="fontWeightBold">
            {talentFullName}
          </Box.span>
        </Box.div>
        <StyledButtonWrapper color={theme.colors.colorTextInverse}>
          <Button
            as={Link}
            leadingIcon="square-arrow-out-up-right"
            size="small"
            to={routes.talentProfile.get(talentCase.talent_id)}
            variant="ghost"
          >
            Go to talent profile
          </Button>
        </StyledButtonWrapper>
      </Box.div>
      <Box.div alignItems="center" display="flex" gap="d6" marginBottom="m4">
        <Heading color="colorTextInverse" marginBottom="m0" size="title-screen">
          {caseType.name} - #{talentCase.id}
        </Heading>
        <Box.div display="flex" gap="d2">
          {isPremiumCase && isAdmin && (
            <Badge color="yellow" icon="crown" size="large">
              {serviceLevelPremium.label}
            </Badge>
          )}
          <Badge {...getCurrentStateBadgeProps[talentCase.current_state]} size="large" />
        </Box.div>
      </Box.div>
      <Box.div alignContent="center" display="flex" gap="d8">
        <CaseUser avatarUrl={hrManager?.avatar_url} name={hrFullName} userType="HR Manager" />
        {isAdmin && (
          <CaseUser
            avatarUrl={caseManager?.avatar_url}
            name={caseManagerFullName}
            userType="Case Manager"
          />
        )}
      </Box.div>
    </BackgroundHeader>
  );
};
