import { Box, Modal, ModalHeader, ModalHeading } from '@localyze-pluto/components';

import { DisclosureStore } from '@ariakit/react';
import { Edit } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/components/Edit/Edit';
import { PostType } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/PostType';
import React from 'react';

export type Props = {
  state: DisclosureStore;
  caseId: number;
};

export const CreateMessageModal = ({ state, caseId }: Props): React.JSX.Element => (
  <Modal store={state}>
    <ModalHeader>
      <ModalHeading>
        <Box.div>Post a new message</Box.div>
      </ModalHeading>
    </ModalHeader>
    <Edit
      caseId={caseId}
      charLimits={{
        Blocker: 256,
        InternalNote: 256,
        Message: 500,
        Milestone: 256,
        Task: 256,
      }}
      modalState={state}
      postType={PostType.Message}
    />
  </Modal>
);
