import React, { useEffect } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  useDrawerStore,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useGlomoRequest } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { trackPageView } from 'modules/analytics/analytics';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { HRGlomoRequestLegacy } from './HRGlomoRequestLegacy';
import { RequestSummary } from 'modules/glomoRequests/components/RequestSummary/RequestSummary';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { FullRequestDrawer } from '../components/FullRequestDrawer/FullRequestDrawer';
import { DenyRequestButton } from 'modules/glomoRequests/components/DenyRequestButton/DenyRequestButton';
import { ApproveForAssessmentButton } from 'modules/glomoRequests/components/ApproveForAssessmentButton/ApproveForAssessmentButton';
import { HRCallout } from 'modules/glomoRequests/components/HRCallout';
import { StatusStepper } from 'components/StatusStepper/StatusStepper';
import { getStatusSteps } from '../utils/getStatusSteps';
import { RiskAssessmentResultSection } from '../components/RiskAssessmentResultSection/RiskAssessmentResultSection';
import { useApproveGlomoRequest } from '../api/useApproveGlomoRequest/useApproveGlomoRequest';
import { useDenyGlomoRequest } from '../api/useDenyGlomoRequest/useDenyGlomoRequest';
import { DenyRequestModal } from '../components/DenyRequestModal/DenyRequestModal';
import { RequiredActionsSection } from '../components/RequiredActionsSection/RequiredActionsSection';
import { GlomoRequestRisk } from '../api/types/GlomoRequestRisk';
import { TripApprovalResult } from '../components/TripApprovalResult/TripApprovalResult';
import { HighlightedRisksAndWarnings } from '../components/HighlightedRisksAndWarnings/HighlightedRisksAndWarnings';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';

export const HRGlomoRequest = (): React.JSX.Element => {
  const { id: glomoRequestId } = useParams<{ id: string }>();
  const { data: glomoRequest, isSuccess } = useGlomoRequest(Number(glomoRequestId));
  const isRiskAssessmentActive = useIsFeatureFlagActive('workation_risk_assessment_result');
  const drawerStore = useDrawerStore();
  const denyModalStore = useModalStore();
  const toast = useToast();
  const routes = useRoutes();

  const { data: destinationCountry } = useCountries({
    enabled: !!glomoRequest?.trip_destination_country_id,
    select: (countries) =>
      countries.find(({ id }) => id === glomoRequest?.trip_destination_country_id),
  });

  const { mutate: aproveGlomoRequest, isPending: isApproving } = useApproveGlomoRequest({
    onSuccess: () => toast('Request successfully approved.', 'success'),
    onError: () => toast('Something went wrong, please try again', 'error'),
  });

  const { mutate: denyGlomoRequest, isPending: isDenying } = useDenyGlomoRequest({
    onSuccess: () => {
      denyModalStore.hide();
      toast('Request denied', 'success');
    },
    onError: () => toast('Something went wrong, please try again', 'error'),
  });

  const requestType = glomoRequest?.type;

  useEffect(() => {
    if (requestType) {
      trackPageView('glomo request: request details', {
        request_type: requestType,
      });
    }
  }, [requestType]);

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  const {
    employee_first_name,
    employee_last_name,
    questions,
    trip_start_date,
    trip_end_date,
    type,
    status,
    destination_in_eu,
    risks_and_warnings,
  } = glomoRequest;

  const employeeFullName = `${employee_first_name} ${employee_last_name}`;
  const isApprovingOrDenying = isApproving || isDenying;
  const isApprovedOrDenied = status === 'request_approved' || status === 'request_denied';

  if (
    !isRiskAssessmentActive ||
    ['complete', 'denied', 'in_assessment', 'pending_approval'].includes(status)
  ) {
    return <HRGlomoRequestLegacy />;
  }

  return (
    <PageLayout
      flexed
      maxW={1000}
      mx="auto"
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          to={routes.glomoRequestsList.url}
          variant="ghost"
        >
          Back to all requests
        </Button>
      }
      w="100%"
    >
      <Box.div display="flex" flexDirection="column" paddingBottom="p8" w="100%">
        <Box.div
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          marginBottom="d8"
        >
          <Box.div alignItems="center" display="flex">
            <Heading as="h1" marginBottom="m0" size="heading40">
              {humanizeGlomoRequestType[type]} request
            </Heading>
          </Box.div>
          {(status === 'pending_approval' || status === 'pending_assessment_approval') && (
            <Box.div display="flex" gap="d2">
              <DenyRequestButton
                glomoRequest={glomoRequest}
                isRiskAssessmentActive={isRiskAssessmentActive}
              />
              <ApproveForAssessmentButton glomoRequest={glomoRequest} />
            </Box.div>
          )}
        </Box.div>
        <Box.div display="flex" flexDirection="column" gap="d8">
          <HRCallout glomoRequest={glomoRequest} />
          <StatusStepper
            backgroundColor="bgPrimary"
            borderColor="borderSecondary"
            borderRadius="borderRadius40"
            borderStyle="borderStyleSolid"
            borderWidth="borderWidth10"
            marginBottom="d4"
            paddingBottom="d10"
            paddingTop="d6"
            steps={getStatusSteps(glomoRequest.status)}
          />
        </Box.div>
        {status === 'risk_assessment_in_review' && (
          <>
            {!isApprovingOrDenying && (
              <RiskAssessmentResultSection
                glomoRequest={glomoRequest}
                onClickApprove={() => {
                  aproveGlomoRequest({ glomoRequestId: glomoRequest.id });

                  trackEvent('glomo request: trip approved', {
                    user_type: 'hr',
                    status: glomoRequest.status,
                    company_id: glomoRequest.company_id,
                    glomo_request_id: glomoRequestId,
                  });
                }}
                onClickDeny={() => denyModalStore.show()}
              />
            )}
            {isApprovingOrDenying && (
              <Box.div padding="d4">
                <ContainedLoadingState />
              </Box.div>
            )}
          </>
        )}
        {(isApprovedOrDenied || status === 'risk_assessment_denied') && (
          <TripApprovalResult marginBottom="d4" status={status} userType="hr" />
        )}
        {isApprovedOrDenied && (
          <RequiredActionsSection
            assessementUrl={glomoRequest.report_pdf_url}
            helpUrl=""
            marginBottom="d4"
            requiredActions={glomoRequest.required_actions}
            riskLevel={glomoRequest.risk_level as GlomoRequestRisk}
            title={status === 'request_approved' ? 'Next steps' : 'Required actions'}
            userType="hr"
          />
        )}
        {['risk_assessment_in_review', 'request_approved', 'request_denied'].includes(status) && (
          <HighlightedRisksAndWarnings
            glomoRequest={glomoRequest}
            marginBottom="d4"
            reportUrl={String(glomoRequest.report_pdf_url)}
            risksAndWarnings={risks_and_warnings}
            userType="hr"
          />
        )}

        {destinationCountry && (
          <RequestSummary
            fullName={employeeFullName}
            isEU={destination_in_eu}
            onClickHeaderButton={() => {
              drawerStore.show();

              trackEvent('glomo request: view full request drawer', {
                user_type: 'hr',
                status: glomoRequest.status,
                company_id: glomoRequest.company_id,
              });
            }}
            tripDestinationCountry={destinationCountry.name}
            tripEndDate={trip_end_date}
            tripStartDate={trip_start_date}
          />
        )}
      </Box.div>
      <DenyRequestModal
        onClickDeny={(denialReason) => {
          denyGlomoRequest({ glomoRequestId: glomoRequest.id, denialReason });

          trackEvent('glomo request: click to deny trip', {
            user_type: 'hr',
            status: glomoRequest.status,
            company_id: glomoRequest.company_id,
            glomo_request_id: glomoRequestId,
          });
        }}
        store={denyModalStore}
      />
      <FullRequestDrawer questions={questions} store={drawerStore} />
    </PageLayout>
  );
};
