export const ADD_TALENT_DEMO = 'add_talent_demo';
export const ADMIN_PRICES = 'admin_prices';
export const BILLING_UI = 'billing_ui';
export const LETTERS_AUTOMATION = 'letters_automation';
export const PAID_PRECHECKS = 'paid_prechecks';
export const REFERRAL_CAMPAIGN = 'referral_campaign';
export const TALENT_DELETION_PERMITTED = 'talent_deletion_permitted';
export const WORKATION_RISK_ASSESSMENT_RESULT = 'workation_risk_assessment_result';
export const MCU_CASE_CREATION = 'mcu_case_creation';

export type Flag =
  | typeof ADD_TALENT_DEMO
  | typeof ADMIN_PRICES
  | typeof BILLING_UI
  | typeof LETTERS_AUTOMATION
  | typeof MCU_CASE_CREATION
  | typeof PAID_PRECHECKS
  | typeof REFERRAL_CAMPAIGN
  | typeof TALENT_DELETION_PERMITTED
  | typeof WORKATION_RISK_ASSESSMENT_RESULT;
