import { SuperModal } from './SuperModal/SuperModal';

import React from 'react';
import { Button, useModalStore } from '@localyze-pluto/components';

export const CreateNewPost = ({ caseId }: { caseId: number }): React.JSX.Element => {
  const modal = useModalStore();
  const isModalOpen = modal.useState('open');

  return (
    <>
      <Button
        leadingIcon="send"
        onClick={() => modal.show()}
        style={{ whiteSpace: 'nowrap' }}
        title="send icon"
        variant="ghost"
      >
        Post something new
      </Button>
      {isModalOpen && <SuperModal caseId={caseId} state={modal} />}
    </>
  );
};
