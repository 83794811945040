import React, { useState } from 'react';

import { Select } from '@localyze-pluto/components';
import { TaskMacro } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/TaskMacro';
import { StatusUpdateMacro } from '../../types/StatusUpdateMacro';

export type Macro = StatusUpdateMacro | TaskMacro;

type Props = {
  onChange: (value: Macro) => void;
  items: StatusUpdateMacro[] | TaskMacro[];
};

export const MacroDropdown = ({ onChange, items }: Props): React.JSX.Element => {
  const [macroId, setMacroId] = useState('');

  const onSelect = (value: string) => {
    const selectedMacro = items.find((item) => item.sys.id === value);
    if (!selectedMacro) return;

    setMacroId(selectedMacro.sys.id);
    onChange(selectedMacro);
  };

  const macros = items.map((item) => ({ label: item.title, value: item.sys.id }));

  return <Select items={macros} setValue={onSelect} value={macroId} />;
};
