import React, { useEffect, useState } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import {
  Anchor,
  Box,
  Button,
  Heading,
  Paragraph,
  useDrawerStore,
  useToast,
} from '@localyze-pluto/components';
import { Link, useParams } from 'react-router-dom';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useGlomoRequest } from 'modules/glomoRequests/api/useGlomoRequest/useGlomoRequest';
import { trackPageView } from 'modules/analytics/analytics';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { AdminGlomoRequestLegacy } from './AdminGlomoRequestLegacy';
import { useCountries } from 'modules/countries/api/useCountries/useCountries';
import { RequestSummary } from '../components/RequestSummary/RequestSummary';
import { FullRequestDrawer } from '../components/FullRequestDrawer/FullRequestDrawer';
import { FormProvider, useForm } from 'react-hook-form';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { UpdateGlomoRequestButton } from 'modules/glomoRequests/components/UpdateGlomoRequestButton/UpdateGlomoRequestButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { AdminCallout } from 'modules/glomoRequests/components/AdminCallout/AdminCallout';
import { StatusStepper } from 'components/StatusStepper/StatusStepper';
import { getStatusSteps } from '../utils/getStatusSteps';
import { RisksAndWarnings } from '../components/RisksAndWarnings/RisksAndWarnings';
import {
  RequiredActions,
  requiredActionsKeys,
} from '../components/RequiredActions/RequiredActions';
import { RiskAssessmentResult } from '../components/RiskAssessmentResult/RiskAssessmentResult';
import { omit } from 'lodash';
import { riskAssessmentResultValidationSchema } from '../validation/riskAssessmentResultValidationSchema';
import { formatRequiredActions } from '../utils/formatRequiredActions';
import { CompleteGlomoRequestValues } from 'modules/glomoRequests/types/CompleteGlomoRequestValues';
import { TripApprovalResult } from '../components/TripApprovalResult/TripApprovalResult';
import { useCompleteGlomoRequest } from '../api/useCompleteGlomoRequest/useCompleteGlomoRequest';
import { RequiredActionsSection } from '../components/RequiredActionsSection/RequiredActionsSection';
import { GlomoRequestRisk } from '../api/types/GlomoRequestRisk';
import { HighlightedRisksAndWarnings } from '../components/HighlightedRisksAndWarnings/HighlightedRisksAndWarnings';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { trackEvent } from 'deprecated/utils/helper/segment';

export const AdminGlomoRequest = (): React.JSX.Element => {
  const { id: glomoRequestId } = useParams<{ id: string }>();
  const [isEditMode, setIsEditMode] = useState(false);
  const isRiskAssessmentActive = useIsFeatureFlagActive('workation_risk_assessment_result');
  const routes = useRoutes();

  const toast = useToast();
  const drawerStore = useDrawerStore();

  const {
    data: glomoRequest,
    isSuccess: isSuccessGlomoRequest,
    isError,
  } = useGlomoRequest(Number(glomoRequestId));

  const { data: company, isSuccess: isSuccessCompany } = useCompany({
    companyId: glomoRequest?.company_id,
    enabled: !!glomoRequest,
  });

  const { data: destinationCountry } = useCountries({
    enabled: !!glomoRequest?.trip_destination_country_id,
    select: (countries) =>
      countries.find(({ id }) => id === glomoRequest?.trip_destination_country_id),
  });

  const methods = useForm<CompleteGlomoRequestValues>({
    defaultValues: {
      riskLevel: '',
      riskAssessmentUrl: '',
      risksAndWarnings: [],
      report_pdf: '',

      a1_exemption_certificate: false,
      certificate_of_cover: false,
      consultation: false,
      consultation_text: '',
      documentation: false,
      employers_certificate: false,
      express_processing: false,
      other: false,
      other_text: '',
      request_a1_certificate: false,
      visa: false,
    },
    mode: 'onChange',
    resolver: yupResolver(riskAssessmentResultValidationSchema),
  });

  const { mutate: completeGlomoRequest, isPending: isCompletingRequest } = useCompleteGlomoRequest({
    onSuccess: () => {
      setIsEditMode(false);
      toast('Request successfully updated.', 'success');
    },
    onError: () => {
      toast('Something went wrong, please try again', 'error');
    },
  });

  const requestType = glomoRequest?.type;

  useEffect(() => {
    if (requestType) {
      trackPageView('glomo request: request details', {
        request_type: requestType,
      });
    }
  }, [requestType]);

  if (isError && !glomoRequest) return <ErrorStatePage />;

  if (!isSuccessGlomoRequest || !isSuccessCompany) {
    return <ContainedLoadingState />;
  }

  const onCancel = () => {
    methods.reset();
    setIsEditMode(false);

    trackEvent('glomo request: click cancel on form', {
      user_type: 'admin',
      status: glomoRequest.status,
      glomo_request_id: glomoRequestId,
    });
  };

  const onSave = (data: CompleteGlomoRequestValues) => {
    completeGlomoRequest({
      values: {
        ...omit(data, requiredActionsKeys),
        requiredActions: formatRequiredActions(data),
      },
      glomoRequestId: Number(glomoRequestId),
    });

    trackEvent('glomo request: save request', {
      user_type: 'admin',
      status: glomoRequest.status,
      glomo_request_id: glomoRequestId,
    });
  };

  const {
    destination_in_eu,
    employee_first_name,
    employee_last_name,
    trip_start_date,
    trip_end_date,
    questions,
    type,
    status,
  } = glomoRequest;

  const employeeFullName = `${employee_first_name} ${employee_last_name}`;
  const isRequestInAssessment = ['in_assessment', 'risk_assessment_in_progress'].includes(status);

  const isApprovedOrDenied = status === 'request_approved' || status === 'request_denied';

  if (
    !isRiskAssessmentActive ||
    ['complete', 'denied', 'in_assessment', 'pending_approval'].includes(status)
  ) {
    return <AdminGlomoRequestLegacy />;
  }

  return (
    <PageLayout
      flexed
      maxW={1000}
      mx="auto"
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          to={routes.glomoRequestsList.url}
          variant="ghost"
        >
          Back to all requests
        </Button>
      }
      w="100%"
    >
      <Box.div display="flex" flexDirection="column" py="p8" w="100%">
        <FormProvider {...methods}>
          <Box.div display="flex" flexDirection="column" gap="d2">
            <Box.div alignItems="center" display="flex" justifyContent="space-between">
              <Box.div alignItems="center" display="flex" gap="d4">
                <Heading as="h1" marginBottom="m0" size="heading40">
                  {humanizeGlomoRequestType[type]} request
                </Heading>
              </Box.div>
              {isRequestInAssessment && (
                <Box.div alignItems="center" display="flex">
                  <Button
                    as={Anchor}
                    // eslint-disable-next-line max-len
                    href="https://www.notion.so/localyze/Workations-Help-Guide-CX-Support-Team-fd65ba3b893249e495fcaea2ecffb253"
                    marginRight="d4"
                    target="_blank"
                    trailingIcon="square-arrow-out-up-right"
                    variant="ghost"
                  >
                    Help guide
                  </Button>
                  <UpdateGlomoRequestButton
                    isEditMode={isEditMode}
                    isLoading={isCompletingRequest}
                    onCancel={onCancel}
                    onEdit={() => setIsEditMode(true)}
                    onSave={methods.handleSubmit(onSave)}
                  />
                </Box.div>
              )}
            </Box.div>
            <Paragraph marginBottom="d8" size="large">
              {employeeFullName} - {company.name}
            </Paragraph>
            <Box.div display="flex" flexDirection="column" gap="d8">
              <AdminCallout glomoRequest={glomoRequest} />
              <StatusStepper
                backgroundColor="bgPrimary"
                borderColor="borderSecondary"
                borderRadius="borderRadius40"
                borderStyle="borderStyleSolid"
                borderWidth="borderWidth10"
                marginBottom="d4"
                paddingBottom="d10"
                paddingTop="d6"
                steps={getStatusSteps(glomoRequest.status)}
              />
            </Box.div>
          </Box.div>
          {isRequestInAssessment && (
            <>
              <RiskAssessmentResult isDisabled={!isEditMode} />
              <RisksAndWarnings isDisabled={!isEditMode} />
              <RequiredActions isDisabled={!isEditMode} marginBottom="d4" />
            </>
          )}
        </FormProvider>
        {(isApprovedOrDenied || status === 'risk_assessment_denied') && (
          <TripApprovalResult marginBottom="d4" status={status} userType="admin" />
        )}
        {isApprovedOrDenied && (
          <RequiredActionsSection
            assessementUrl={glomoRequest.risk_assessment_url as string}
            // eslint-disable-next-line max-len
            helpUrl="https://www.notion.so/localyze/Workations-Help-Guide-CX-Support-Team-fd65ba3b893249e495fcaea2ecffb253"
            marginBottom="d4"
            requiredActions={glomoRequest.required_actions}
            riskLevel={glomoRequest.risk_level as GlomoRequestRisk}
            title={status === 'request_approved' ? 'Next steps' : 'Required actions'}
            userType="admin"
          />
        )}
        {isApprovedOrDenied && (
          <HighlightedRisksAndWarnings
            glomoRequest={glomoRequest}
            marginBottom="d4"
            reportUrl={String(glomoRequest.report_pdf_url)}
            risksAndWarnings={glomoRequest.risks_and_warnings}
            userType="admin"
          />
        )}
        {destinationCountry && (
          <RequestSummary
            fullName={employeeFullName}
            isEU={destination_in_eu}
            onClickHeaderButton={() => {
              drawerStore.show();

              trackEvent('glomo request: view full request drawer', {
                user_type: 'admin',
                status: glomoRequest.status,
                glomo_request_id: glomoRequestId,
              });
            }}
            tripDestinationCountry={destinationCountry.name}
            tripEndDate={trip_end_date}
            tripStartDate={trip_start_date}
          />
        )}
      </Box.div>
      <FullRequestDrawer questions={questions} store={drawerStore} />
    </PageLayout>
  );
};
