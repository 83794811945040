import React, { useEffect, useState } from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import {
  Box,
  Button,
  Icon,
  Tooltip,
  TooltipAnchor,
  useDrawerStore,
  useToast,
  useTooltipStore,
} from '@localyze-pluto/components';
import { isEmpty } from 'lodash';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { GlomoRequestFilters } from 'modules/glomoRequests/components/GlomoRequestFilters/GlomoRequestFilters';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { GlomoRequestFilterCommonKey } from 'modules/glomoRequests/constants';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { trackPageView } from 'modules/analytics/analytics';
import { useGlomoRequests } from 'modules/glomoRequests/api/useGlomoRequests/useGlomoRequests';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { GlomoRequestsTable } from 'modules/glomoRequests/components/GlomoRequestsTable';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { GlomoRequestsEmptyState } from 'modules/glomoRequests/components/GlomoRequestsEmptyState';
import { getGlomoRequestFilters } from '../utils/getGlomoRequestFilters';
import { useCurrentUser } from 'config/CurrentUserContext';
import { FiltersData, FiltersDrawer } from '../components/FiltersDrawer/FiltersDrawer';
import { ColumnId } from '../components/GlomoRequestRowCell';

const tooltipTitle =
  'Share this link with your talent so they can submit a workation or business trip request.';

type TableColumn = { id: ColumnId; name: string };

const columns: TableColumn[] = [
  { id: 'Name', name: 'Name' },
  { id: 'RequestType', name: 'Request type' },
  { id: 'TravelStartDate', name: 'Travel start date' },
  { id: 'Status', name: 'Status' },
  { id: 'RiskLevel', name: 'Risk level' },
  { id: 'RequestDate', name: 'Request date' },
];

// TODO: remove once workation_risk_assessment_result is deleted
const getTableColumns = (isRiskAssessmentActive: boolean): TableColumn[] => {
  const notification: TableColumn = { id: 'Notification', name: '' };
  const approvedOrDeniedDate: TableColumn = {
    id: 'ApprovedOrDeniedDate',
    name: 'Approved/denied on',
  };

  return [
    ...(isRiskAssessmentActive ? [notification] : []),
    ...columns,
    ...(isRiskAssessmentActive ? [] : [approvedOrDeniedDate]),
  ];
};

export const HRGlomoRequests = (): React.JSX.Element => {
  const toast = useToast();

  useEffect(() => {
    trackPageView('glomo requests: list');
  }, []);

  const drawerStore = useDrawerStore();
  const copyTooltipStore = useTooltipStore({ placement: 'left' });

  const { queryParams, addQueryParam, removeQueryParam, updateQueryParams } = useQueryParams();

  const [riskLevel, setRiskLevel] = useState<GlomoRequestRisk | undefined>(
    queryParams.risk_level as GlomoRequestRisk,
  );

  const [status, setStatus] = useState<GlomoRequestStatus | undefined>(
    queryParams.status as GlomoRequestStatus,
  );

  const [requestType, setRequestType] = useState<GlomoRequestType | undefined>(
    queryParams.type as GlomoRequestType,
  );

  const isRiskAssessmentActive = useIsFeatureFlagActive('workation_risk_assessment_result');

  const filters = getGlomoRequestFilters(true, isRiskAssessmentActive);

  filters[0].value = riskLevel;
  filters[1].value = status;
  filters[2].value = requestType;

  const { company_id } = useCurrentUser();

  const { data: company, isPending: isPendingCompany } = useCompany({ companyId: company_id });

  const { data: glomoRequests, isPending: isPendingGlomoRequests } = useGlomoRequests({
    params: {
      risk_level: riskLevel,
      status: status ? [status] : status,
      type: requestType,
      sort_by: 'created_at',
      sort_direction: 'desc',
    },
  });

  const onChange = (key: GlomoRequestFilterCommonKey, value: string) => {
    if (key === 'risk_level') {
      setRiskLevel((value || undefined) as GlomoRequestRisk);
    } else if (key === 'status') {
      setStatus((value || undefined) as GlomoRequestStatus);
    } else {
      setRequestType((value || undefined) as GlomoRequestType);
    }

    value !== '' ? addQueryParam(String(key), String(value)) : removeQueryParam(key);
  };

  const onApplyFilters = (filtersData: FiltersData) => {
    setRiskLevel(filtersData.risk_level || undefined);
    setStatus(filtersData.status || undefined);
    setRequestType(filtersData.type || undefined);

    updateQueryParams(filtersData);

    drawerStore.hide();

    trackEvent('glomo request: filter requests', {
      user_type: 'hr',
      status: filtersData.status,
      type: filtersData.type,
      risk_level: filtersData.risk_level,
    });
  };

  const glomoRequestsURL = `${process.env.REACT_APP_TALENT_APP}/requests/${company?.uid}`;

  if (isPendingCompany) {
    return <ContainedLoadingState />;
  }

  const onCopyToClipboardWithSuccess = () => {
    toast('URL copied!', 'success');

    trackEvent(`glomo requests: copy company url to clipboard`, {
      company_id: company?.id,
      company_name: company?.name,
    });
  };

  return (
    <PageLayout flexed title="Manage Requests">
      <Box.div display="flex" flexDirection="column" gap="d6" marginBottom="m8">
        <Box.div display="flex" justifyContent="space-between">
          <Box.div alignItems="center" display="flex" gap="d3">
            {isRiskAssessmentActive ? (
              <Button leadingIcon="sliders-vertical" onClick={drawerStore.show} variant="secondary">
                Filter
              </Button>
            ) : (
              <GlomoRequestFilters filters={filters} onChange={onChange} />
            )}
          </Box.div>
          <Box.div alignItems="center" display="flex" gap="d2">
            {isRiskAssessmentActive && (
              <TooltipAnchor render={<Box.div />} store={copyTooltipStore}>
                <Icon
                  color="contentTertiary"
                  decorative={false}
                  display="flex"
                  icon="info"
                  size="sizeIcon30"
                  title={tooltipTitle}
                />
              </TooltipAnchor>
            )}

            <CopyToClipboard
              onError={() => toast('Failed to copy URL.', 'error')}
              onSuccess={onCopyToClipboardWithSuccess}
              textToCopy={glomoRequestsURL}
            />
          </Box.div>
        </Box.div>
        {isPendingGlomoRequests ? (
          <ContainedLoadingState />
        ) : (
          <>
            {isEmpty(glomoRequests) ? (
              <GlomoRequestsEmptyState />
            ) : (
              <GlomoRequestsTable
                columns={getTableColumns(isRiskAssessmentActive)}
                glomoRequests={glomoRequests as GlomoRequest[]}
              />
            )}
          </>
        )}
      </Box.div>
      {isRiskAssessmentActive && (
        <FiltersDrawer
          filters={filters}
          initialFilterValues={{
            risk_level: riskLevel,
            status: status,
            type: requestType,
          }}
          onApply={onApplyFilters}
          store={drawerStore}
        />
      )}
      {isRiskAssessmentActive && <Tooltip store={copyTooltipStore}>{tooltipTitle}</Tooltip>}
    </PageLayout>
  );
};
