import { AdminRoutes, HrRoutes, useRoutes } from 'modules/routes/useRoutes/useRoutes';
import {
  NavEntry,
  NavItemComponent,
  NavLink,
} from 'modules/layout/components/Navbar/types/NavLink';
import { isNavComponent, isNavGroup, isNavLink } from './helpers';
import { NAVBAR } from './constants';
import React from 'react';
import { commonIconClasses } from './styles';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { Icon, IconProps } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { ADMIN_ROUTES } from 'modules/routes/admin_routes';
import { HR_ROUTES } from 'modules/routes/hr_routes';

const isNavLinkAllowed = (navItem: NavLink, allowedPathnames: string[]): boolean => {
  const isAnAllowedPath = !!allowedPathnames.find(
    (pathname) => pathname === navItem.to.split('?')[0],
  );
  const isFeatureEnabled =
    navItem.withFeatureToggle === true || navItem.withFeatureToggle === undefined;

  return isAnAllowedPath && isFeatureEnabled;
};

const isNavItemAllowed = (navItem: NavEntry, allowedPathnames: string[]): boolean => {
  return (
    isNavComponent(navItem) ||
    isNavGroup(navItem) ||
    (isNavLink(navItem) && isNavLinkAllowed(navItem, allowedPathnames))
  );
};

const getAllowedNavItems = (navItems: NavEntry[], allowedPathnames: string[]): NavEntry[] =>
  navItems.reduce((allowedNavItems: NavEntry[], navItem: NavEntry) => {
    if (isNavItemAllowed(navItem, allowedPathnames)) {
      return [
        ...allowedNavItems,
        isNavGroup(navItem)
          ? {
              ...navItem,
              items: getAllowedNavItems(navItem.items, allowedPathnames),
            }
          : navItem,
      ];
    }

    return allowedNavItems;
  }, []);

const NavIcon = ({ icon }: { icon: IconProps['icon'] }) => (
  <Icon className={commonIconClasses} decorative icon={icon} size="sizeIcon30" />
);

const getAllowedPathnames = (routes: AdminRoutes | HrRoutes): string[] =>
  Object.entries(routes).map(([_key, route]) => route.url);

export const useNavData = (): NavEntry[] => {
  const { id: currentUserId, isHrAdmin, isAdmin, isHrViewer, company_id } = useCurrentUser();

  const showInvoicesLink = isHrAdmin && !isAdmin;
  const showBilling = useIsFeatureFlagActive('billing_ui') && isAdmin;
  const showAdminPrices = useIsFeatureFlagActive('admin_prices') && isAdmin;

  const routes = useRoutes();

  const navItems: NavEntry[] = [
    {
      icon: <NavIcon icon="home" />,
      label: NAVBAR.HOME,
      to: routes.home.url,
    },
    {
      icon: <NavIcon icon="list" />,
      label: NAVBAR.MY_CASES,
      to: routes.cases.get(isAdmin, isHrViewer, currentUserId),
    },
    {
      icon: <NavIcon icon="circle-check" />,
      label: NAVBAR.MY_TASKS,
      to: `${routes.tasks.url}?page=1`,
      counter: 'pending_tasks_count',
    },
    {
      icon: <NavIcon icon="users" />,
      label: NAVBAR.MY_TALENT,
      to: routes.talents.url,
    },
    {
      icon: <NavIcon icon="clipboard-check" />,
      label: NAVBAR.PRE_CHECK,
      to: HR_ROUTES.preCheck.url,
    },
    {
      icon: <NavIcon icon="briefcase-business" />,
      label: NAVBAR.COMPANIES,
      to: ADMIN_ROUTES.companies.url,
    },
    {
      icon: <NavIcon icon="mail-question" />,
      label: NAVBAR.REQUESTS,
      to: routes.glomoRequestsList.url,
      counter: 'pending_requests_count',
    },
    {
      icon: <NavIcon icon="lightbulb" />,
      label: NAVBAR.KNOWLEDGE,
      to: routes.knowledge.url,
    },
    {
      icon: <NavIcon icon="square-pen" />,
      label: NAVBAR.PIPELINE_EDITOR,
      to: ADMIN_ROUTES.pipelineEditor.url,
    },
    {
      icon: <NavIcon icon="variable" />,
      label: NAVBAR.VARIABLES,
      to: ADMIN_ROUTES.variables.url,
    },
    {
      icon: <NavIcon icon="banknote" />,
      label: NAVBAR.BILLING,
      to: ADMIN_ROUTES.billing.url,
      withFeatureToggle: showBilling,
    },
    {
      icon: <NavIcon icon="badge-euro" />,
      label: NAVBAR.PRICES,
      to: ADMIN_ROUTES.prices.url,
      withFeatureToggle: showAdminPrices,
    },
    {
      icon: <NavIcon icon="file-bar-chart" />,
      label: NAVBAR.REPORTING,
      to: HR_ROUTES.reporting.url,
      withFeatureToggle: isHrAdmin,
    },
    {
      icon: <NavIcon icon="sliders-vertical" />,
      items: [
        {
          label: NAVBAR.COMPANY_PROFILE,
          to: routes.companyProfile.url,
          route: routes.companyProfile.get(company_id),
        },
        {
          label: NAVBAR.OFFICES,
          to: HR_ROUTES.offices.url,
        },
        {
          label: NAVBAR.TEAM,
          to: routes.team.url,
        },
        {
          label: NAVBAR.INTEGRATIONS,
          to: HR_ROUTES.integrations.url,
        },
        ...(showInvoicesLink
          ? [
              {
                label: NAVBAR.INVOICES,
                component: NavItemComponent.InvoiceModal,
              },
            ]
          : []),
      ],
      label: NAVBAR.SETTINGS,
    },
  ];

  return getAllowedNavItems(navItems, getAllowedPathnames(routes));
};
