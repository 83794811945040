import { Route } from 'types/Route';

export type HROnlyRoutes = {
  preCheck: Route;
  offices: Route;
  subscription: Route;
  integrations: Route;
  reporting: Route;
};

export const HR_ROUTES: HROnlyRoutes = {
  preCheck: {
    url: '/pre-check',
    get: () => '/pre-check',
  },
  offices: {
    url: '/offices',
    get: () => '/offices',
  },
  subscription: {
    url: '/billing',
    get: () => '/billing',
  },
  integrations: {
    url: '/integrations',
    get: () => '/integrations',
  },
  reporting: {
    url: '/reporting',
    get: () => '/reporting',
  },
};
