import { Box, Button, Heading, Text } from '@localyze-pluto/components';
import { Link } from 'react-router-dom';
import React from 'react';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

type SuccessProps = {
  caseId?: number;
};

export const Success = ({ caseId }: SuccessProps): React.JSX.Element => {
  const routes = useRoutes();

  return (
    <Box.div padding="d9" textAlign="center">
      <Box.img marginBottom="d10" src="images/inbox.svg" />
      <Heading as="h1" color="colorTextHeadingStrong" size="heading30">
        Your case was submitted
      </Heading>
      <Text.p fontSize="fontSize50" fontWeight="fontWeightMedium" marginBottom="d10">
        This Talent has been invited to begin the case evaluation.
      </Text.p>
      <Box.div display="flex" gap="d3" justifyContent="center">
        {caseId ? (
          <Button as={Link} to={routes.case.get(caseId)} variant="secondary">
            View case details
          </Button>
        ) : (
          <Button as={Link} to={routes.cases.url} variant="secondary">
            See all Cases
          </Button>
        )}
        <Button as={Link} to={routes.createCase.get()} variant="primary">
          Create another case
        </Button>
      </Box.div>
    </Box.div>
  );
};
