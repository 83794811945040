import { ContentfulClient, ContentfulClientInterface } from 'react-contentful';
import { RawAdditionalServiceContent } from 'modules/additionalServices/types/AdditionalServiceContent';
import { Macro } from 'modules/pipelines/pages/caseEvaluationSummary/types/Macro';
import { PostType } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/PostType';
import { TaskMacro } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/TaskMacro';
import { StatusUpdateMacro } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/StatusUpdateMacro';
import { ReferralCard } from 'modules/home/types/ReferralCard';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';

type Entries = Promise<unknown[]>;

type Card = ReferralCard & {
  sys: {
    createdAt: string;
  };
};

type ContentfulDocument = {
  nameOfTheDocument: string;
  pdf: Asset;
};

type ContentfulFAQ = { question: string; answer: Document };
type ContentfulWaitingTime = {
  countryFlag: Asset;
  destinationCountry: string;
  institution: string;
  process: string;
  trafficLight: string;
  waitingTime: string;
};

export class ContentfulAPI {
  contentTypeFaq: string;
  contentTypeResources: string;
  contentTypeWaitingTime: string;
  contentTypeDocumentsToTranslate: string;
  contentTypeCaseEvaluationSummaryMacros: string;
  contentTypeTalentUpdateMacro: string;
  contentTypeTalentTaskMacro: string;
  additionalServicesMarketplace: string;
  referralCard: string;
  contentfulClient: ContentfulClientInterface;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.contentfulClient = new (ContentfulClient as any)({
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      space: process.env.REACT_APP_CONTENTFUL_SPACE,
    }) as ContentfulClientInterface;
    this.contentTypeFaq = 'knowledgePageFaqs';
    this.contentTypeResources = 'knowledgePageResources';
    this.contentTypeWaitingTime = 'knowledgePageWaitingTimes';
    this.contentTypeDocumentsToTranslate = 'documents-to-translate';
    this.contentTypeCaseEvaluationSummaryMacros = 'caseEvaluationSummaryMacro';
    this.contentTypeTalentUpdateMacro = 'talentUpdateMacro';
    this.contentTypeTalentTaskMacro = 'talentTaskMacro';
    this.additionalServicesMarketplace = 'additionalServicesMarketplace';
    this.referralCard = 'referralCardDashboard';
  }

  fetchData = async (contentType: string, entries: Array<unknown> = [], skip = 0): Entries => {
    const response = await this.contentfulClient.getEntries({ content_type: contentType, skip });
    const { items, total, skip: skipped, limit } = response;

    const shouldFetchMore = total > skipped + limit;
    entries = entries.concat(items.map((item) => item.fields));

    if (shouldFetchMore) {
      return this.fetchData(contentType, entries, skipped + limit);
    }

    return entries;
  };

  fetchFaq = (): Promise<ContentfulFAQ[]> =>
    this.fetchData(this.contentTypeFaq) as Promise<ContentfulFAQ[]>;

  fetchKnowledgeDocuments = (): Promise<ContentfulDocument[]> =>
    this.fetchData(this.contentTypeResources) as Promise<ContentfulDocument[]>;

  fetchWaitingTime = (): Promise<ContentfulWaitingTime[]> =>
    this.fetchData(this.contentTypeWaitingTime) as Promise<ContentfulWaitingTime[]>;

  fetchDocumentsToTranslate = async (caseType?: string, destinationCountry?: string): Entries => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeDocumentsToTranslate,
      'fields.caseType': caseType,
      'fields.country': destinationCountry,
    });

    return data.items.map((item) => item.fields);
  };

  fetchCaseEvaluationSummaryMacros = async ({
    destinationCountry,
    originCountry,
  }: {
    destinationCountry?: string;
    originCountry?: string;
  }): Promise<Macro[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeCaseEvaluationSummaryMacros,
      'fields.destinationCountry[in]': `Default,${destinationCountry}`,
      'fields.originCountry[in]': `Default,${originCountry}`,
    });

    return data.items.map((item) => item.fields) as Macro[];
  };

  fetchTalentUpdateMacros = async ({ type }: { type: PostType }): Promise<StatusUpdateMacro[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeTalentUpdateMacro,
      'fields.type[in]': `Default,${type}`,
    });

    return data.items.map((item) => {
      const { fields } = item;
      // include the id here for the macroDropdown
      return { sys: { id: item.sys.id }, ...(fields as object) };
    }) as StatusUpdateMacro[];
  };

  fetchAdditionalServices = (): Promise<RawAdditionalServiceContent[]> =>
    this.fetchData(this.additionalServicesMarketplace) as Promise<RawAdditionalServiceContent[]>;

  fetchTaskMacros = async (): Promise<TaskMacro[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.contentTypeTalentTaskMacro,
    });

    return data.items.map((e) => {
      const { fields } = e;
      // include the id here for the macroDropdown
      return { sys: { id: e.sys.id }, ...(fields as object) };
    }) as TaskMacro[];
  };

  fetchReferralCard = async (): Promise<Card[]> => {
    const data = await this.contentfulClient.getEntries({
      content_type: this.referralCard,
    });

    return data.items.map((item) => {
      const { fields, sys } = item;

      return { ...(fields as ReferralCard), sys };
    });
  };
}
