import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { TaskMacro } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/TaskMacro';

export const useTaskMacros = ({ enabled }: { enabled: boolean }): UseQueryResult<TaskMacro[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['talent-task-macro'],
    queryFn: () => contentfulAPI.fetchTaskMacros(),
    enabled,
  });
};
