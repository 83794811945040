import { Badge, Heading } from '@localyze-pluto/components';
import { CardWithTabs } from 'components/CardWithTabs/CardWithTabs';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCase } from 'modules/cases/api/useCase/useCase';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CreateNewPost } from './CreateNewPost';
import { CreateNewMessage } from './CreateNewMessage';
import { Intercom } from 'modules/caseDetails/components/CaseActivityCard/Intercom/Intercom';
import { CASE_ACTIVITY_TABS } from 'modules/caseDetails/utils/constants';
import { humanizeCommunicationPriority } from 'modules/caseDetails/utils/humanizeCommunicationPriority';

export const CaseActivityCard = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const { id } = useParams<{ id: string }>();
  const caseId = Number(id);
  const { data: talentCase, isSuccess: isSuccessCase } = useCase({
    caseId,
  });
  const { Header, Content } = CardWithTabs;

  if (!isSuccessCase) {
    return <ContainedLoadingState />;
  }

  return (
    <CardWithTabs h={400} maxH={400} tabs={CASE_ACTIVITY_TABS}>
      <Header
        action={
          isAdmin ? (
            <>
              <CreateNewPost caseId={caseId} />
              <Intercom talentId={talentCase.talent_id} />
            </>
          ) : (
            <CreateNewMessage caseId={caseId} />
          )
        }
        heading={
          <>
            <Heading color="colorTextStrongest" marginBottom="m0" size="title-subsection">
              Case activity
            </Heading>
            {isAdmin && (
              <Badge>{humanizeCommunicationPriority(talentCase.communication_priority)}</Badge>
            )}
          </>
        }
        tabListAriaLabel="Case activity tabs"
      />
      <Content />
    </CardWithTabs>
  );
};
