import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ContentfulAPI } from 'config/contentfulApi/ContentfulAPI';
import { PostType } from 'modules/caseDetails/components/CaseActivityCard/SuperModal/types/PostType';
import { StatusUpdateMacro } from '../../types/StatusUpdateMacro';

export const useStatusUpdateMacros = ({
  type,
  enabled,
}: {
  type: PostType;
  enabled: boolean;
}): UseQueryResult<StatusUpdateMacro[]> => {
  const contentfulAPI = new ContentfulAPI();

  return useQuery({
    queryKey: ['talent-update-macro', type],
    queryFn: () => contentfulAPI.fetchTalentUpdateMacros({ type }),
    enabled,
  });
};
